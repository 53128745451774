var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-appbar'),_c('div',{staticStyle:{"position":"relative","background":"var(--v-background_normal-base)"},style:(_vm.$vuetify.breakpoint.mdAndUp ? 'margin-top: 60px' : 'margin-top: 52px')},[_c('div',{staticStyle:{"z-index":"0","position":"absolute","width":"100vw"}},[_c('v-img',{attrs:{"src":require('@/assets/images/MyPage/MyPageBackground.jpg'),"max-height":"433px","min-height":"300px","gradient":"to top, rgba(0,0,0,0.6), rgba(19,42,86,0.6)"}})],1),_c('v-container',{staticClass:"d-flex",staticStyle:{"max-width":"1380px !important"},attrs:{"fluidd":"","pa-0":""}},[_c('v-card',{class:_vm.$vuetify.breakpoint.smAndUp ? 'mx-10' : 'mx-4',staticStyle:{"padding-bottom":"60px"},style:(_vm.$vuetify.breakpoint.lgAndUp
            ? 'padding-top: 100px'
            : _vm.$vuetify.breakpoint.smAndUp
            ? 'padding-top: 80px'
            : 'padding-top: 60px'),attrs:{"color":"transparent","width":"100%","flat":""}},[_c('div',{staticClass:"d-flex-column"},[_c('div',{staticClass:"font-weight-bold white_light_global--text",class:_vm.$vuetify.breakpoint.lgAndUp ? 'h1' : 'h2'},[_vm._v(" "+_vm._s(_vm.$t("mypage.title"))+" ")]),_c('div',{staticClass:"font-weight-bold white_light_global--text mt-5",class:_vm.$vuetify.breakpoint.lgAndUp ? 'title-1 ' : 'subtitle-1',staticStyle:{"max-width":"480px"}},[_vm._v(" "+_vm._s(_vm.$t("mypage.subtitle"))+" ")])]),_c('div',{style:(_vm.$vuetify.breakpoint.lgAndUp
              ? 'height: 80px'
              : _vm.$vuetify.breakpoint.smAndUp
              ? 'height: 60px'
              : 'height: 40px')}),_c('v-card-text',{staticClass:"pa-0"},[_c('router-view',{staticStyle:{"z-index":"1","position":"relative","width":"100%"},attrs:{"userData":_vm.userData,"orderData":_vm.orderData},on:{"saveData":_vm.saveData}})],1)],1)],1)],1),_c('page-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }